import { FormInstance } from 'antd'
import React, { ComponentType, useContext } from 'react'

import { EcrfAnswer, EcrfQuestionAnswer, QuestionType } from '../../../../../requests'
import { ITestFormState, TestFormContext } from './TestFormContext'

// for global questions we don't have productId. We need '0' to have consistent field names
export const getFieldName = (questionId: string, productId: number) => `${questionId}-${productId || 0}`

export const parseTestFormAnswers = (answers: EcrfAnswer[], formInstance: FormInstance) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const values: { [index: string]: any } = {}
  const answered: string[] = []

  const questionAnswers = (answers as EcrfQuestionAnswer[]) || [] // we don't have RM in SBS
  const currentAnswers = formInstance.getFieldsValue()

  questionAnswers.forEach(answer => {
    if (
      answer.type === QuestionType.Calcul ||
      answer.isPrefilled ||
      !(getFieldName(answer.blockId, answer.productId) in currentAnswers)
    ) {
      values[getFieldName(answer.blockId, answer.productId)] = answer.value
    }
    answered.push(getFieldName(answer.blockId, answer.productId))
  })

  return { values, answered }
}

export type PropsFromContext = Partial<ITestFormState>
export type PropsFromContextKeys = (keyof Partial<ITestFormState>)[]

// this HOC helps us not to have unnecessary re-renders
export function TestFormContextWrapper<T>(
  Component: ComponentType<T & PropsFromContext>,
  keys: (keyof ITestFormState)[]
) {
  return (props: T) => {
    const ctx = useContext(TestFormContext)
    const neededContextValues = keys.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: ctx[curr as keyof ITestFormState]
      }),
      {}
    )
    const newProps = { ...props, ...neededContextValues }
    return React.useMemo(() => <Component {...newProps} />, [...Object.values(newProps)])
  }
}
