import { RecordStatus, SideBySideTest } from '../../../../../requests'
import { ITestFormState, minColumnWidth } from './TestFormContext'
import { getFieldName } from './TestFormUtils'

export enum TestFormAction {
  SET_RECORD,
  SET_RECORD_STATUS,
  UPDATE_UNANSWERED_REQUIRED_QUESTIONS,
  SET_REQUIRED_QUESTION_ALERT,
  SET_ANSWERED_QUESTION_IDS,
  UPDATE_ANSWERED_QUESTIONS,
  SET_SECTION_AND_SUBSECTION_IDS,
  SET_PRODUCT_ARROWS,
  SET_COLUMN_WIDTH,
  UPDATE_PROCESSING_QUESTIONS_COUNT,
  UPDATED_AFTER_PROCESSING
}

export type TestFormStateActions =
  | { type: TestFormAction.SET_RECORD; record: SideBySideTest }
  | { type: TestFormAction.SET_RECORD_STATUS; status: RecordStatus }
  | { type: TestFormAction.UPDATE_UNANSWERED_REQUIRED_QUESTIONS; questionId?: string; isAnswered?: boolean }
  | { type: TestFormAction.SET_REQUIRED_QUESTION_ALERT; requiredQuestionsAlert: boolean }
  | {
      type: TestFormAction.SET_ANSWERED_QUESTION_IDS
      answeredQuestions: Set<string>
    }
  | { type: TestFormAction.UPDATE_ANSWERED_QUESTIONS; questionId: string; productId: number; isAnswerAdded: boolean }
  | { type: TestFormAction.SET_SECTION_AND_SUBSECTION_IDS; sectionId: string; subsectionId: string }
  | {
      type: TestFormAction.SET_PRODUCT_ARROWS
      showProductsLeftArrow: boolean
      showProductsRightArrow: boolean
    }
  | { type: TestFormAction.SET_COLUMN_WIDTH; formWidth: number }
  | { type: TestFormAction.UPDATE_PROCESSING_QUESTIONS_COUNT; isProcessing: boolean }
  | { type: TestFormAction.UPDATED_AFTER_PROCESSING }

export const testFormStateReducer = (state: ITestFormState, action: TestFormStateActions): ITestFormState => {
  let newUnansweredRequiredQuestions = new Set() as Set<string>
  let newAnsweredQuestionIds = new Set() as Set<string>
  let fieldName = ''
  let productsCount = 0
  let basicColumnWidth = 0
  let columnWidth = 0

  switch (action.type) {
    case TestFormAction.SET_RECORD:
      return { ...state, record: action.record }
    case TestFormAction.SET_RECORD_STATUS:
      return { ...state, record: { ...state.record, status: action.status } }

    case TestFormAction.UPDATE_UNANSWERED_REQUIRED_QUESTIONS:
      if (!action.questionId) return { ...state, unansweredRequiredQuestions: new Set() }
      newUnansweredRequiredQuestions = state.unansweredRequiredQuestions
      if (action.isAnswered) newUnansweredRequiredQuestions.delete(action.questionId)
      else newUnansweredRequiredQuestions.add(action.questionId)
      return { ...state, unansweredRequiredQuestions: newUnansweredRequiredQuestions }
    case TestFormAction.SET_REQUIRED_QUESTION_ALERT:
      return { ...state, requiredQuestionsAlert: action.requiredQuestionsAlert }
    case TestFormAction.SET_ANSWERED_QUESTION_IDS:
      return {
        ...state,
        answeredQuestions: action.answeredQuestions,
        isReadyToShow: true
      }
    case TestFormAction.UPDATE_ANSWERED_QUESTIONS:
      newAnsweredQuestionIds = new Set(state.answeredQuestions)
      fieldName = getFieldName(action.questionId, action.productId)
      if (!action.isAnswerAdded && state.answeredQuestions.has(fieldName)) {
        newAnsweredQuestionIds.delete(fieldName)
      }
      if (action.isAnswerAdded && !state.answeredQuestions.has(fieldName)) {
        newAnsweredQuestionIds.add(fieldName)
      }
      return { ...state, answeredQuestions: newAnsweredQuestionIds }
    case TestFormAction.SET_SECTION_AND_SUBSECTION_IDS:
      return {
        ...state,
        sectionId: action.sectionId,
        subsectionId: action.subsectionId,
        isReadyToShow: false,
        processedQuestionsCount: 0,
        shouldUpdateAfterProcessing: false
      }
    case TestFormAction.SET_PRODUCT_ARROWS:
      return {
        ...state,
        showProductsLeftArrow: action.showProductsLeftArrow,
        showProductsRightArrow: action.showProductsRightArrow
      }
    case TestFormAction.SET_COLUMN_WIDTH:
      productsCount = state.record?.products.length || 1
      basicColumnWidth = action.formWidth / Math.ceil(action.formWidth / minColumnWidth)
      columnWidth =
        basicColumnWidth * productsCount < action.formWidth ? action.formWidth / productsCount : basicColumnWidth

      return {
        ...state,
        columnWidth: columnWidth * productsCount < action.formWidth ? action.formWidth / productsCount : columnWidth,
        showProductsLeftArrow: false,
        showProductsRightArrow: columnWidth * productsCount > action.formWidth
      }
    case TestFormAction.UPDATE_PROCESSING_QUESTIONS_COUNT:
      return {
        ...state,
        shouldUpdateAfterProcessing: true,
        processedQuestionsCount: state.processedQuestionsCount + (action.isProcessing ? 1 : -1)
      }
    case TestFormAction.UPDATED_AFTER_PROCESSING:
      return {
        ...state,
        shouldUpdateAfterProcessing: false
      }
    default:
      throw new Error()
  }
}
