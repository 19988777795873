import './SideBySideProjectTests.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  CenterData,
  SelectionKeys,
  SideBySideTest,
  SideBySideTestSorter,
  SorterOrder,
  fetchSideBySideTests
} from '../../../../requests'
import { routes } from '../../../../routes'
import { createTableRecords } from '../../../../utils'
import { DatacBulkActionsBar, DatacMessage, DatacTable, DatacTableSearchAndFilters } from '../../../common'
import { SideBySideProjectDetailsLayout, SideBySideProjectDetailsTab } from '../SideBySideProjectDetailsLayout'
import { useSideBySideProjectDetailsStore } from '../SideBySideProjectDetailsStore'
import { getListColumns, searchAndFilterOptions } from './SideBySideProjectTestsTableConfig'
import { TestsExport } from './TestsExport'

const pageSize = 25

interface Props extends RouteComponentProps {
  isLoadingProject: boolean
}

export const SideBySideProjectTests: React.FC<Props> = ({ isLoadingProject }) => {
  const { project, reloadTestsTable, testsTableOptions, setTestsTableOptions, tests, setTests, filters, setFilters } =
    useSideBySideProjectDetailsStore()
  const intlTests = useScopedIntl('side_by_side.project.tests')
  const intl = useScopedIntl('')
  const [currentPage, setCurrentPage] = useState(1)
  const [countAll, setCountAll] = useState(0)
  const [sorter, setSorter] = useState(testsTableOptions.sorter)
  const [search, setSearch] = useState(testsTableOptions.search)
  const [isFetchingTests, setIsFetchingTests] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [selectedTests, setSelectedTests] = useState<SelectionKeys>([])
  const [isEverythingSelected, setIsEverythingSelected] = useState(false)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    if (isLoadingProject) return

    refreshList()
  }, [reloadTestsTable])

  useEffect(() => {
    if (isLoadingProject) return

    fetchTestsPage(0, sorter, search, filters)
  }, [isLoadingProject, search, filters, currentPageSize])

  const refreshList = () => {
    fetchTestsPage(currentPage - 1, sorter, search, filters)
  }

  const fetchTestsPage = (
    page: number,
    sorter: SideBySideTestSorter,
    search: string,
    filters: Record<string, string[]>
  ) => {
    setSelectedTests([])
    setIsFetchingTests(true)
    fetchSideBySideTests(
      {
        projectId: project.id,
        options: {
          limit: currentPageSize,
          offset: page * currentPageSize,
          sorter,
          search,
          filters: {
            centers: filters?.centers
          }
        }
      },
      {
        onSuccess: ({ tests, countAll }) => {
          setTests(createTableRecords<SideBySideTest>(tests))
          setCountAll(countAll)
          setIsFetchingTests(false)
          setIsSearching(false)
          setCurrentPage(page + 1)
          setTestsTableOptions({ pageNumber: page, search, sorter })
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingTests(false)
          setIsSearching(false)
        }
      }
    )
  }

  const onSearchChange = (value: string) => {
    setIsSearching(true)
    setSearch(value)
  }

  const onPageChange = (page: number) => {
    fetchTestsPage(page - 1, sorter, search, filters)
  }

  const onSorterChange = (tableSorter: SideBySideTestSorter) => {
    const sorterChanged =
      Object.keys(tableSorter).length &&
      ((!sorter && tableSorter.order) || tableSorter.field !== sorter?.field || tableSorter.order !== sorter?.order)

    if (sorterChanged) {
      const newSorter = tableSorter.order
        ? {
            field: tableSorter.field,
            order: tableSorter.order === 'ascend' ? SorterOrder.Ascend : SorterOrder.Descend
          }
        : null
      fetchTestsPage(currentPage - 1, newSorter, search, filters)
      setSorter(newSorter)
    }
  }

  const columns = getListColumns({
    columnNames: {
      testId: intlTests('column_name.test_id'),
      datacaptId: intlTests('column_name.contact'),
      creationDate: intlTests('column_name.creation_date'),
      status: intlTests('column_name.status'),
      centerName: intlTests('column_name.center'),
      lastUpdated: intlTests('column_name.last_updated')
    }
  })

  const onRowConfig = (test: SideBySideTest) => ({
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLElement

      if (
        target.closest('ul')?.classList.value.includes('ant-dropdown-menu') ||
        target.closest('div')?.classList.value.includes('ant-popover') ||
        target.matches('.datac-icon-box')
      ) {
        return
      }

      navigate(routes.sideBySideProjectTest(project.id, test.id))
    }
  })

  const selectedCount = isEverythingSelected ? countAll : selectedTests.length

  return (
    <SideBySideProjectDetailsLayout currentTab={SideBySideProjectDetailsTab.Tests}>
      {!isLoadingProject && (
        <>
          <div className="side_by_side-project-tests">
            <DatacTableSearchAndFilters
              onSearchChange={onSearchChange}
              onFiltersChange={setFilters}
              isSearching={isSearching}
              searchAndFilterOptions={searchAndFilterOptions(
                intl,
                project.centers.map((c: CenterData) => ({ value: c.id, label: c.name }))
              )}
              initialSearchValue={search}
              initialFilterValues={filters}
            />
            <DatacTable
              dataSource={tests}
              loading={isFetchingTests}
              columns={columns}
              selectedRows={selectedTests}
              setSelectedRows={setSelectedTests}
              setIsEverythingSelected={setIsEverythingSelected}
              scroll={{ x: 1050 }}
              onRow={onRowConfig}
              pagination={{
                current: currentPage,
                onChange: onPageChange,
                total: countAll,
                disabled: isFetchingTests,
                pageSize: currentPageSize
              }}
              setPageSize={setCurrentPageSize}
              onChange={(_, __, sorter) => onSorterChange(sorter as SideBySideTestSorter)}
            />
          </div>
          <DatacBulkActionsBar
            selectedCount={selectedCount}
            onClose={() => {
              setSelectedTests([])
              setIsEverythingSelected(false)
            }}
            actions={[
              {
                label: intl('common.export'),
                icon: 'download',
                onClick: () => setIsExportModalOpened(true)
              }
            ]}
          />
          <TestsExport
            onClose={() => setIsExportModalOpened(false)}
            isOpened={isExportModalOpened}
            projectId={project?.id}
            search={search}
            filters={filters}
            selectedTests={selectedTests}
            isEverythingSelected={isEverythingSelected}
          />
        </>
      )}
    </SideBySideProjectDetailsLayout>
  )
}
