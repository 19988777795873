import './TestForm.less'

import { RouteComponentProps, useMatch } from '@gatsbyjs/reach-router'
import debounce from 'lodash/debounce'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { Structure, Subsection } from '../../../../../requests'
import { DatacLoading, DatacMessage } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'
import { SideBySideProjectTestLayout } from '../SideBySideProjectTestLayout'
import { SideBySideProjectTestZones } from '../SideBySideProjectTestZones'
import { TestFormEnd } from './TestFormEnd'
import { TestFormFooter } from './TestFormFooter'
import { Navigation } from './TestFormNavigation'
import { TestFormProducts } from './TestFormProducts'
import { TestFormAction } from './TestFormReducer'
import { TestFormSubsection } from './TestFormSubsection'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper } from './TestFormUtils'

interface Props extends RouteComponentProps {
  recordId?: string
}

const contextProps: PropsFromContextKeys = [
  'record',
  'updateRecord',
  'updateSubsection',
  'fetchStructure',
  'fetchSubsection',
  'navigateToSubsection',
  'sectionId',
  'subsectionId',
  'testFormDispatch',
  'processedQuestionsCount',
  'shouldUpdateAfterProcessing'
]

const TestFormInner: React.FC<Props & PropsFromContext> = ({
  record,
  updateRecord,
  updateSubsection,
  fetchStructure,
  fetchSubsection,
  navigateToSubsection,
  testFormDispatch,
  sectionId,
  subsectionId,
  processedQuestionsCount,
  shouldUpdateAfterProcessing
}) => {
  const intlEcrf = useScopedIntl('studies.fulfillment')
  const match = useMatch(':sectionId/:subsectionId')
  const [structure, setStructure] = useState<Structure>(null)
  const [activeSubsection, setActiveSubsection] = useState<Subsection>(null)
  const { testContentRef } = useSideBySideProjectDetailsStore()
  const [isShowingEndTest, setIsShowingEndTest] = useState(false)

  const changeColumnWidth = () => {
    testContentRef.scrollTo({ left: 0, behavior: 'smooth' })
    testFormDispatch({
      type: TestFormAction.SET_COLUMN_WIDTH,
      formWidth: testContentRef.clientWidth
    })
  }

  const handleResize = debounce(() => {
    changeColumnWidth()
    // some ui elements will change smoothly, so we need additional width check after a while
    setTimeout(changeColumnWidth, 500)
  }, 100)

  useEffect(() => {
    if (!testContentRef || !record?.products.length) return () => null

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [testContentRef, record?.products.length])

  const handleScroll = () => {
    testFormDispatch({
      type: TestFormAction.SET_PRODUCT_ARROWS,
      showProductsLeftArrow: testContentRef.scrollLeft > 0,
      showProductsRightArrow: testContentRef.scrollLeft + testContentRef.clientWidth < testContentRef.scrollWidth
    })
  }

  useEffect(() => {
    if (!testContentRef) return () => null
    testContentRef.addEventListener('scroll', handleScroll)
    return () => testContentRef.removeEventListener('scroll', handleScroll)
  }, [testContentRef])

  useEffect(() => {
    testFormDispatch({
      type: TestFormAction.SET_SECTION_AND_SUBSECTION_IDS,
      sectionId: match?.sectionId,
      subsectionId: match?.subsectionId
    })
    updateRecord(false)
  }, [])

  useEffect(() => {
    if (record?.isFormStarted) {
      fetchStructure({
        onSuccess: structure => {
          setStructure(structure)
          updateRecord(false)
        },
        onRequestError: () =>
          DatacMessage.error(intlEcrf('error.fetch_structure.title'), intlEcrf('error.fetch_structure.body'))
      })
    }
  }, [record?.isFormStarted])

  useEffect(() => {
    if (processedQuestionsCount || !shouldUpdateAfterProcessing) return

    testFormDispatch({
      type: TestFormAction.UPDATED_AFTER_PROCESSING
    })
    updateRecord()
  }, [processedQuestionsCount, shouldUpdateAfterProcessing])

  useEffect(() => {
    if (subsectionId) {
      setActiveSubsection(null)
      fetchSubsection(
        { sectionId, subsectionId },
        {
          onSuccess: subsection => {
            testFormDispatch({
              type: TestFormAction.UPDATE_UNANSWERED_REQUIRED_QUESTIONS
            })
            setActiveSubsection(subsection)
          },
          onRequestError: () =>
            DatacMessage.error(intlEcrf('error.fetch_subsection.title'), intlEcrf('error.fetch_subsection.body')),
          onError: () => setActiveSubsection(null)
        }
      )
    }
  }, [sectionId, subsectionId])

  useEffect(() => {
    if (structure && !subsectionId) goToSubsection(structure?.sections?.[0]?.subsections?.[0]?.id)
  }, [structure, subsectionId])

  useEffect(() => {
    if (subsectionId) {
      updateSubsection()
    }
  }, [subsectionId])

  const findSectionBySubsectionId = (subsectionId: string) =>
    structure?.sections?.find(section => section?.subsections?.find(subsection => subsection.id === subsectionId))

  const goToSubsection = (newSubsectionId: string) => {
    if (newSubsectionId !== subsectionId) {
      const section = findSectionBySubsectionId(newSubsectionId)
      navigateToSubsection(section?.id, newSubsectionId || undefined)
    }
  }

  const showSubsection = !!activeSubsection && !!record && !!structure && !isShowingEndTest
  const showNavigation = !!record?.isFormStarted && !!record?.id && !!structure

  return (
    <SideBySideProjectTestLayout
      menuContent={!!showNavigation && <Navigation structure={structure} onSubsectionChange={goToSubsection} />}
      test={record}
      setTest={record => testFormDispatch({ type: TestFormAction.SET_RECORD, record })}
    >
      <DatacLoading isLoading={!record}>
        {record?.isFormStarted ? (
          <>
            {showSubsection && (
              <div className="test-form">
                <TestFormProducts />
                <TestFormSubsection subsection={activeSubsection} />
                <TestFormFooter
                  subsections={structure.sections.reduce((acc, section) => [...acc, ...section.subsections], [])}
                  onNavigateSubsection={goToSubsection}
                  onNavigateEndTest={() => setIsShowingEndTest(true)}
                />
              </div>
            )}
            {isShowingEndTest && <TestFormEnd testId={record.id} onBack={() => setIsShowingEndTest(false)} />}
          </>
        ) : (
          <SideBySideProjectTestZones
            test={record}
            setTest={record => testFormDispatch({ type: TestFormAction.SET_RECORD, record })}
          />
        )}
      </DatacLoading>
    </SideBySideProjectTestLayout>
  )
}

export const TestForm = TestFormContextWrapper<Props>(TestFormInner, contextProps)
