import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { Form } from 'antd'
import { Color } from 'antd/es/color-picker'
import React from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { SideBySideChartFormula } from '../../../../../requests'
import { DatacColorPicker, DatacIcon } from '../../../../common'

interface FormulaListItemProps {
  formula: SideBySideChartFormula
  onChange: (formula: SideBySideChartFormula) => void
}

const FormulaListItem: React.FC<FormulaListItemProps> = props => {
  const { formula, onChange } = props
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: formula.id })
  const [formInstance] = Form.useForm()

  const onColorChange = (color: Color) => {
    onChange({
      ...formula,
      color: `#${color.toHex()}`
    })
  }

  const style = transform
    ? {
        transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
        transition
      }
    : undefined

  return (
    <div
      className="side_by_side-chart-wizard__formulas-editor__list-item"
      ref={setNodeRef}
      style={style}
      key={formula.id}
    >
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__brand">{formula.brand_name}</div>
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__number">{formula.formula_number}</div>
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__color-picker">
        <Form form={formInstance} initialValues={{ color: formula.color }}>
          <Form.Item
            name="color"
            noStyle
            getValueFromEvent={color => {
              onColorChange(color)
              return `#${color.toHex()}`
            }}
          >
            <DatacColorPicker showText={() => <DatacIcon name="chevronDown" raw />} />
          </Form.Item>
        </Form>
      </div>
      <div className="side_by_side-chart-wizard__formulas-editor__drag-handle" {...listeners} {...attributes}>
        <DatacIcon name="moreVertical" />
      </div>
    </div>
  )
}

interface FormulaListProps {
  formulas: SideBySideChartFormula[]
  onChange: (formulas: SideBySideChartFormula[]) => void
}

const FormulaList: React.FC<FormulaListProps> = ({ formulas, onChange }) => {
  const onItemChange = (changedformula: SideBySideChartFormula) => {
    onChange(
      formulas.map(formula => {
        if (formula.id !== changedformula.id) {
          return formula
        }
        return changedformula
      })
    )
  }

  return (
    <div className="side_by_side-chart-wizard__formulas-editor__list">
      <SortableContext items={formulas.map(formula => formula.id)}>
        {formulas.map((formula, index) => (
          <FormulaListItem key={formula.id} formula={formula} onChange={onItemChange} />
        ))}
      </SortableContext>
    </div>
  )
}

interface Props {
  formulas: SideBySideChartFormula[]
  onChange: (formulas: SideBySideChartFormula[]) => void
  openDrawer: () => void
}

export const FormulasEditor: React.FC<Props> = ({ formulas, onChange, openDrawer }) => {
  const intlChart = useScopedIntl('side_by_side.project.chart')

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active?.id !== over?.id) {
      const oldIndex = formulas.findIndex(formula => formula.id === active?.id)
      const newIndex = formulas.findIndex(formula => formula.id === over?.id)
      onChange(arrayMove(formulas, oldIndex, newIndex))
    }
  }

  return (
    <div className="side_by_side-chart-wizard__formulas-editor">
      <DndContext onDragEnd={onSortEnd}>
        <FormulaList formulas={formulas} onChange={onChange} />
      </DndContext>
      <div
        className="side_by_side-chart-wizard__formulas-editor__add-formula"
        onClick={openDrawer}
        role="button"
        tabIndex={0}
      >
        <DatacIcon name="plus" raw />
        {intlChart('add_formula')}
      </div>
    </div>
  )
}
