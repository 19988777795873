import './SideBySideProjectSettings.less'

import { RouteComponentProps, Router } from '@gatsbyjs/reach-router'
import React, { useContext } from 'react'

import { RedirectNoAccessWrapper } from '../../../RedirectNoAccessWrapper'
import { UserContext } from '../../../auth'
import { SideBySideProjectDetailsLayout, SideBySideProjectDetailsTab } from '../SideBySideProjectDetailsLayout'
import { SideBySideProjectSettingsGeneral } from './SideBySideProjectSettingsGeneral'
import { SideBySideProjectSettingsLayout } from './SideBySideProjectSettingsLayout'
import { SideBySideProjectSettingsPanelists } from './SideBySideProjectSettingsPanelists'
import { SideBySideProjectSettingsUsers } from './SideBySideProjectSettingsUsers'

export const SideBySideProjectSettings: React.FC<RouteComponentProps> = () => {
  const { user } = useContext(UserContext)

  return (
    <SideBySideProjectDetailsLayout currentTab={SideBySideProjectDetailsTab.Settings}>
      <SideBySideProjectSettingsLayout>
        <Router>
          <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="/">
            <SideBySideProjectSettingsGeneral path="/" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="users">
            <SideBySideProjectSettingsUsers path="*" />
          </RedirectNoAccessWrapper>
          <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="panelists">
            <SideBySideProjectSettingsPanelists path="*" />
          </RedirectNoAccessWrapper>
        </Router>
      </SideBySideProjectSettingsLayout>
    </SideBySideProjectDetailsLayout>
  )
}
