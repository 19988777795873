import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  CenterData,
  TableColumnVariableSource,
  TableVariable,
  fetchSubjectRepositoryVariables,
  updateProject
} from '../../../../../requests'
import { DatacMessage, DatacTableColumnPicker } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

export const SideBySideProjectSettingsPanelists: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const [allSubjectVariables, setAllSubjectVariables] = useState<TableVariable[]>()
  const { project, setProject } = useSideBySideProjectDetailsStore()

  useEffect(() => {
    if (!project) return

    updateAllVariablesList()
  }, [project])

  const updateAllVariablesList = () => {
    fetchSubjectRepositoryVariables({
      onSuccess: setAllSubjectVariables,
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  return (
    !!allSubjectVariables && (
      <div className="side-by-side-project-settings__content__body">
        <DatacTableColumnPicker
          allColumns={{
            [TableColumnVariableSource.SubjectDatabase]: allSubjectVariables
          }}
          columns={
            project?.panelistVariables?.map((panelistVariable: string) => {
              const variable = allSubjectVariables?.find(tableVariable => tableVariable.variable === panelistVariable)
              return {
                source: TableColumnVariableSource.SubjectDatabase,
                label: variable?.variable,
                value: variable?.variable,
                sublabel: variable?.title
              }
            }) || []
          }
          updateColumns={columns => {
            updateProject(
              {
                ...project,
                panelistVariables: columns.map(tableColumn => tableColumn.value),
                centerIds: project.centers.map((center: CenterData) => center.id),
                projectId: project.id
              },
              {
                onSuccess: newProject => {
                  setProject({ ...project, ...newProject })
                },
                onRequestError: code => DatacMessage.genericError(intl, code)
              }
            )
          }}
          isLoading={false}
          isPopover={false}
          maxColumns={10}
          intlPrefix="side_by_side.project.panelist_information"
          maxSourceVariables={50}
        />
      </div>
    )
  )
}
