import { RouteComponentProps } from '@gatsbyjs/reach-router'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Product, fetchBrands, fetchProjectProducts } from '../../../../requests'
import { DatacDropdownMenu, DatacDropdownMenuOptions, DatacMessage, DatacOption } from '../../../common'
import { ProductActions, ProductsTable } from '../../../shared/ProductsTable'
import { SideBySideProjectDetailsLayout, SideBySideProjectDetailsTab } from '../SideBySideProjectDetailsLayout'
import { useSideBySideProjectDetailsStore } from '../SideBySideProjectDetailsStore'
import { EditProductModal } from './EditProductModal'

const pageSize = 25

enum ProductsTableAction {
  duplicate = 'duplicate',
  edit = 'edit'
}

const ActionCell: React.FC<{
  product: Product
  onDuplicate: (product: Product) => void
  onEdit: (product: Product) => void
}> = ({ product, onDuplicate, onEdit }) => {
  const menuOptions: DatacDropdownMenuOptions<ProductsTableAction> = [
    {
      type: ProductsTableAction.edit,
      label: 'side_by_side.products.formula.edit',
      icon: 'edit'
    },
    {
      type: ProductsTableAction.duplicate,
      label: 'side_by_side.products.formula.duplicate',
      icon: 'copy'
    }
  ]

  const handleMenuClick = (item: string) => {
    switch (item) {
      case ProductsTableAction.edit:
        onEdit(product)
        break
      case ProductsTableAction.duplicate:
        onDuplicate(product)
        break
      default:
        break
    }
  }

  return (
    <div className="inclusions-table__row-actions">
      <DatacDropdownMenu options={menuOptions} onClick={item => handleMenuClick(item)} />
    </div>
  )
}

export const SideBySideProjectProducts: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const { project, reloadProductsTable, triggerReloadProductsTable } = useSideBySideProjectDetailsStore()
  const [productForAction, setProductForAction] = useState<Product>(null)
  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])

  useEffect(() => {
    updateBrandList()
  }, [])

  const updateBrandList = () => {
    fetchBrands({
      onSuccess: brands => setBrandOptions(brands.map(brand => ({ value: brand, label: brand }))),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  const onDuplicate = (product: Product) => setProductForAction({ ...product, id: null })
  const onEdit = (product: Product) => setProductForAction(product)

  const onModalClose = () => {
    setProductForAction(null)
    triggerReloadProductsTable()
  }

  return (
    <SideBySideProjectDetailsLayout currentTab={SideBySideProjectDetailsTab.Products}>
      {project?.id && (
        <ProductsTable
          showStats
          pageSize={pageSize}
          fetchProducts={fetchProjectProducts(project.id)}
          brandOptions={brandOptions}
          actionCell={product => <ActionCell product={product} onDuplicate={onDuplicate} onEdit={onEdit} />}
          reloadTable={reloadProductsTable}
          projectCategory={project.category}
          allowedActions={[ProductActions.Disable]}
        />
      )}
      <EditProductModal onClose={onModalClose} product={productForAction} />
    </SideBySideProjectDetailsLayout>
  )
}
